/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.btn-grp>button:not(:last-child){
    margin-right: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-group, .form-group>label{
    margin-bottom: 10px;
}

.custom-select{
    padding: .375rem .75rem;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
    color: #222222;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.custom-select:not(:disabled):not(.mat-select-disabled):not(.disable-dropdown):focus{
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.custom-select.mat-select-disabled, .custom-select.disable-dropdown{
  opacity: 0.5;
  cursor: default;
}

.custom-select.mat-select-disabled .mat-select-trigger,
.custom-select.disable-dropdown .mat-select-trigger{
  cursor: default;
}

input.is-invalid:-webkit-autofill, input.is-invalid:-webkit-autofill:focus,
input.is-invalid:-internal-autofill-selected {
  -webkit-text-fill-color: red !important;
}

.astrick-class, .error-message{
    color: red;
}

.form-control.is-invalid{
  color: red !important;
  background-image: none !important;
}

input:-webkit-autofill, input:-webkit-autofill:focus,
input:-internal-autofill-selected {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.mat-select-placeholder {
  color: rgb(146 137 133) !important;
}

.form-control.right-icon + .input-group-append.transparent{
  background-color: transparent;
  margin-left: -65px !important;
  z-index: 3;
  border:none;
  display: flex;
  align-items: center;
}

.input-group-text>.bi:first-child{
  margin-right: 10px;
}

.input-group-text>em.bi.inactive-input-icon{
  opacity: 0.6;
  cursor: default;
}

.input-group-text>em.bi:not(.disable-input-icon):not(.inactive-input-icon){
  cursor: pointer;
}

.form-control.right-icon + .input-group-append.transparent .input-group-text{
  background-color: transparent;
  border: none;
  line-height: 1 !important;
  font-size: 17px;
}

.mat-chip-list-wrapper input.mat-input-element, .mat-chip-list-wrapper .mat-standard-chip{
  margin: 2px 3px !important;
  padding: 7px 8px;
  min-width: 40px;
  justify-content: center;
}

.mat-tab-label, .mat-tab-link, .mat-chip {
  font-size: 13px !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  overflow-y: scroll;
  overflow-x: scroll;
  background: white;
  /* box-shadow: inset 0 0 4px #707070; */
}

::-webkit-scrollbar-thumb {
  background: #C4C4C4;
  border-radius: 40px;
}